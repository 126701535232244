export const USE_TYPE = {
  QR: 'QR',
  COMMON: 'COMMON',
  VIDEO: 'VIDEO',
} as const

export const UTILITY_TYPE = {
  TICKET: 'TICKET',
  UTILITY: 'UTILITY',
}
