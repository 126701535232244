import { useCallback } from 'react'

import { BottomSheet } from '@/components/core-ui'
import QRCodeSection from '@/components/QRCodeSection'
import { useRefreshContext } from '@/shared/contexts'
import { IDialogProps } from '@/shared/types'

export const ClaimUseBenefitsQRBottomSheet = ({ isOpened, closeDialog }: IDialogProps) => {
  const { refreshRefetch } = useRefreshContext()

  const closeBottomSheet = useCallback(() => {
    refreshRefetch()
    closeDialog()
  }, [closeDialog, refreshRefetch])

  if (!isOpened) return <></>

  return (
    <BottomSheet closeSheet={closeBottomSheet} center={true}>
      <div className="relative h-full px-4">
        <button
          onClick={closeBottomSheet}
          className="absolute my-[20px] mx-[16px] right-0 cursor-pointer">
          <img src="/img/close.svg" alt="close icon" />
        </button>
        <QRCodeSection />
      </div>
    </BottomSheet>
  )
}
