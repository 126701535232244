import { PropsWithChildren, SyntheticEvent, useCallback } from 'react'

import { ManagedDialog } from '@/shared/constants'
import { useDialog } from '@/shared/hooks'

import DialogManager from './DialogManager'

const DialogProvider = ({ children }: PropsWithChildren) => {
  const { selectedDialog, setDialog } = useDialog()

  const openDialog = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()
      if (!(e.target instanceof HTMLButtonElement)) return

      const {
        target: {
          dataset: { dialog },
        },
      } = e
      if (dialog) setDialog(dialog as ManagedDialog)
    },
    [setDialog],
  )

  const closeDialog = useCallback(() => setDialog(null), [setDialog])

  return (
    <div onClick={openDialog}>
      {children}
      <DialogManager dialog={selectedDialog} closeDialog={closeDialog} />
    </div>
  )
}

export default DialogProvider
