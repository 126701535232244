import { useTranslation } from 'react-i18next'

function Error() {
  const { t } = useTranslation('web-checkout')

  return (
    <div className="w-full mt-[60px] lg:mt-[132px]">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-5 items-center">
          <div>
            <span className="text-[80px] lg:text-[130px] leading-tight font-extrabold font-mark">
              500
            </span>
          </div>
          <div className="flex flex-col gap-1 font-normal leading-normal text-sm lg:text-lg font-Pretendard text-center whitespace-pre-line">
            {t('web-checkout:checkout.result-fail-page-description-error-215')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
