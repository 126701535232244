import { useTranslation } from 'react-i18next'

const LoginError = () => {
  const { t } = useTranslation('common')

  return (
    <div className="w-full mt-[250px] lg:mt-[300px]">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-5 items-center">
          <div className="flex flex-col gap-1 font-normal leading-normal text-sm lg:text-lg font-Pretendard text-center">
            <span className="text-textPrimary">{t('common:common.error-auth-information')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginError
