import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'

import { BottomSheet, Spinner } from '@/components/core-ui'
import { showToast } from '@/components/utils'
import { useRefreshContext } from '@/shared/contexts'
import { IDialogProps } from '@/shared/types'
import { cx } from '@/shared/utils'
import { selectSelectedBenefit, selectSelectedVideoUrl } from '@/store/claim/claimSlice'
import { useAppSelector } from '@/store/hooks'

export const ClaimUseBenefitsVideoBottomSheet = ({ isOpened, closeDialog }: IDialogProps) => {
  const { t } = useTranslation(['web-modal', 'web-claim'])
  const selectedBenefit = useAppSelector(selectSelectedBenefit)
  const selectedVideoUrl = useAppSelector(selectSelectedVideoUrl)
  const { selectedTitle, selectedBenefitDate } = selectedBenefit

  const { refreshRefetch } = useRefreshContext()
  const [isVideoError, setIsVideoError] = useState(false)
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(true)

  const handleClose = useCallback(() => {
    refreshRefetch()
    closeDialog()
  }, [closeDialog, refreshRefetch])

  //TODO 김유상: 추후에 API 연동 후 mapping 등 추가 작업 예정
  const title = useMemo(() => {
    return t('web-claim:web-claim.title-video')
  }, [t])

  const BenefitTitle = useCallback(() => {
    return (
      <div className="flex flex-col items-center">
        <div className="w-full text-xl font-semibold text-center overflow-hidden text-ellipsis line-clamp-2">
          {selectedTitle?.title}
        </div>
        <div className="w-full text-lg font-medium text-center overflow-hidden text-ellipsis line-clamp-2 mt-4">
          {selectedTitle?.subTitle}
        </div>
      </div>
    )
  }, [selectedTitle?.subTitle, selectedTitle?.title])

  const RenderVideoSection = () => {
    if (selectedVideoUrl === null) return <></>
    return (
      <>
        <div className={cx(`${isVideoLoading || isVideoError ? 'hidden' : ''}`)}>
          <ReactPlayer
            url={selectedVideoUrl}
            playing={false}
            muted
            controls
            width={'100%'}
            height={'100%'}
            onError={() => setIsVideoError(true)}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
            onProgress={progress => {
              if (progress.loaded > 0.0) {
                setIsVideoLoading(false)
              } else {
                setIsVideoLoading(true)
              }
            }}
            onReady={() => {
              setIsVideoError(false)
              setIsVideoLoading(false)
            }}
          />
        </div>
        {isVideoLoading && (
          <div className="w-full h-full bg-bgPrimary flex items-center justify-center py-[75px]">
            <Spinner />
          </div>
        )}
        {isVideoError && (
          <div className="w-full h-full bg-bgQuarternary flex items-center justify-center py-[75px]">
            <img src="/img/hc-alert-line.svg" alt="" className="w-[60px] h-[60px]" />
          </div>
        )}
      </>
    )
  }

  useEffect(() => {
    if (isVideoError)
      showToast({
        text: t('web-modal:web-modal.confirm-claim-fail'),
        toastId: 'useVideoBenefit',
      })
  }, [isVideoError, t])

  if (!isOpened) return <></>

  return (
    <BottomSheet closeSheet={handleClose} center={true}>
      <div className="relative h-full">
        <button
          onClick={handleClose}
          className="absolute my-[20px] mx-[16px] right-0 cursor-pointer">
          <img src="/img/close.svg" alt="close icon" />
        </button>
        <div className="h-[64px] flex justify-center items-center text-center">
          <div className="text-lg leading-normal font-medium break-words">{title}</div>
        </div>
        <div className="h-[600px] pt-[25px] overflow-y-auto scrollbar-hide">
          <div className="flex flex-col gap-2 items-center justify-center w-full">
            <BenefitTitle />
            {selectedBenefitDate?.selectedDisplayTime && (
              <div className="text-textAccent text-sm">{selectedTitle?.bottomSheetSubText}</div>
            )}
          </div>
          <div className="mt-10 w-full">
            <RenderVideoSection />
          </div>
        </div>
      </div>
    </BottomSheet>
  )
}
