import { useMutation } from '@tanstack/react-query'

import { IUpdateBenefitsStatusResponse } from '@/shared/types'
import withAxios from '@/shared/utils/api'

export const postBenefitsStatus = async (statusId?: string, itemId?: string) => {
  const url = `/api/v1/app/private/status/${statusId}`
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IUpdateBenefitsStatusResponse>>({
    url,
    method: 'POST',
    data: { itemId },
  })

  return data
}

export const usePostBenefitsStatus = () => {
  return useMutation(
    ['useUpdateBenefitsStatus'],
    ({ statusId, itemId }: { statusId: string; itemId: string }) =>
      postBenefitsStatus(statusId, itemId),
    {
      retry: 2,
      retryDelay: 1000,
    },
  )
}
