import { Middleware } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authSlice from '../auth/authSlice'

export const persistConfig = {
  key: 'auth',
  whitelist: [authSlice.name],
  storage,
}

const persistMiddleware: Middleware = () => dispatch => async action => {
  if (action.type === REHYDRATE && action.key === 'root') {
    dispatch(action)
  } else {
    dispatch(action)
  }
}

export default persistMiddleware
