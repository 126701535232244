import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ManagedDialog } from '@/shared/constants'

import { AppState } from '..'

export interface IDialogState {
  selectedDialog: ManagedDialog | null
  isPreparingToOpenDialog: boolean
}

const initialState: IDialogState = {
  selectedDialog: null,
  isPreparingToOpenDialog: false,
}

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    allClose: state => {
      state.selectedDialog = null
      state.isPreparingToOpenDialog = false
    },
    setDialog: (state, action: PayloadAction<ManagedDialog | null>) => {
      state.selectedDialog = action.payload
    },
    setIsPreparingToOpenDialog(state, action: PayloadAction<boolean>) {
      state.isPreparingToOpenDialog = action.payload
    },
  },
})

export const selectSelectedDialog = (state: AppState) => state.dialog.selectedDialog
export const selectIsPreparingToOpenDialog = (state: AppState) =>
  state.dialog.isPreparingToOpenDialog

export const { allClose, setDialog, setIsPreparingToOpenDialog } = dialogSlice.actions

export default dialogSlice
