import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { i18n } from 'next-i18next'

import { exchangingAccessToken } from '../services/auth'
import { LocalStorage, sendToSentryWithExtra } from '../utils'
import { getEnvValue } from './getEnvValue'

export interface IRequestConfig extends AxiosRequestConfig {
  suppressStatusCode?: number[]
}

const isNetworkError = (err: any) => {
  if (err.code === 'ECONNABORTED') {
    return false
  }
  return !!err.isAxiosError && !err.response
}

const changeRefreshTokenToAccessToken = async (
  originalRequest: IRequestConfig,
): Promise<AxiosResponse<unknown, any> | undefined> => {
  if (typeof window === 'undefined') return
  try {
    const refreshToken = LocalStorage.getItem('refreshToken')
    if (refreshToken === null) throw new Error('Failed to get refresh token')
    const token = await exchangingAccessToken(refreshToken)
    if (token !== undefined) {
      LocalStorage.setItem('accessToken', token.accessToken as string)
      LocalStorage.setItem('refreshToken', token.refreshToken as string)
      LocalStorage.setItem('refreshTokenExpiresAt', token.refreshTokenExpiresAt?.toString())
      return withAxios(originalRequest)
    }
  } catch (e: unknown) {
    if (e instanceof Error) {
      sendToSentryWithExtra(e)
      throw new Error('Failed to exchange refresh token')
    }
  }
}

async function onErrorResponse(error: AxiosError | Error) {
  if (axios.isAxiosError(error)) {
    const originalRequest = error?.config as IRequestConfig
    const response = error?.response as AxiosResponse

    if (isNetworkError(error)) {
      sendToSentryWithExtra(error)
    }

    if (response?.status === 401) {
      try {
        changeRefreshTokenToAccessToken(originalRequest)
      } catch (e: unknown) {
        if (e instanceof Error) {
          sendToSentryWithExtra(e)
        }
      }
    }
  } else {
    sendToSentryWithExtra(error)
  }
  return Promise.reject(error)
}

async function onResponse(response: AxiosResponse): Promise<AxiosResponse> {
  const originalRequest = response.config
  const { status } = response
  if (status === 401) {
    try {
      changeRefreshTokenToAccessToken(originalRequest)
    } catch (e: unknown) {
      if (e instanceof Error) {
        sendToSentryWithExtra(e)
        return Promise.reject(e)
      }
    }
  } else if (status >= 400 && status !== 401) {
    return Promise.reject(response)
  }
  return response
}

function onRequest(config: InternalAxiosRequestConfig) {
  const pass = ['oauth', 'public']
  if (pass.filter(u => config.url?.includes(u)).length !== 0) return config

  const accessToken = LocalStorage.getItem('accessToken')
  if (!accessToken) return Promise.reject('No Access Token')

  config.headers.Authorization = `Bearer ${accessToken}`

  return config
}

export default async function withAxios<T>(requestConfig: IRequestConfig) {
  const instance = axios.create()

  // locale 정보 셋팅을 위해 요청 인터셉터를 추가
  instance.interceptors.request.use(
    config => {
      const locale = i18n?.language || 'ko'

      if (locale) {
        config.headers['Accept-Language'] = locale as string
      }

      return onRequest(config)
    },
    error => Promise.reject(error),
  )

  if (typeof window !== 'undefined') {
    instance.interceptors.request.use(
      config => onRequest(config),
      error => Promise.reject(error),
    )
    instance.interceptors.response.use(
      response => onResponse(response),
      error => onErrorResponse(error),
    )
  }

  const apiBaseUrl =
    getEnvValue('apiBaseUrl') ?? 'https://dfgr7cpxai.execute-api.ap-northeast-2.amazonaws.com'

  const response = await instance.request<T>({
    ...requestConfig,
    baseURL: apiBaseUrl,
    timeout: 30000,
  })

  return response
}
