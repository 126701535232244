import { useQuery } from '@tanstack/react-query'

import { IPrivateUtilities } from '@/shared/types'
import withAxios from '@/shared/utils/api'

export const getPrivateUtilities = async ({
  collectionAddress,
}: {
  collectionAddress?: string | null
}) => {
  const url = `/api/v1/app/private/utilities?collectionAddress=${collectionAddress}`
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IPrivateUtilities>>({
    url,
    method: 'GET',
  })
  return data
}

export const useGetPrivateUtilities = (pathname: string, collectionAddress: string | null) => {
  return useQuery(
    [pathname, 'getPrivateUtilities'],
    () => getPrivateUtilities({ collectionAddress }),
    {
      enabled: collectionAddress !== null,
      retry: 2,
      retryDelay: 1000,
    },
  )
}
