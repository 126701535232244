import axios from 'axios'

export const getConfig = async () => {
  const {
    data: {
      privateApiBaseUrl,
      publicApiBaseUrl,
      useVConsole,
      s3HostUrl,
      cdnHostUrl,
      oauthRefreshTokenExpiresIn,
      nodeEnv,
      authApiBaseUrl,
      sentryDsn,
      apiBaseUrl,
    },
  } = await axios.get('/api/config')
  return {
    privateApiBaseUrl,
    publicApiBaseUrl,
    useVConsole,
    authApiBaseUrl,
    s3HostUrl,
    cdnHostUrl,
    oauthRefreshTokenExpiresIn,
    nodeEnv,
    sentryDsn,
    apiBaseUrl,
  }
}
