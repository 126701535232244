import Script from 'next/script'

import { getEnvValue } from '@/shared/utils/getEnvValue'

const VConsole = () => {
  const useVConsole = getEnvValue('useVConsole')

  if (useVConsole !== 'true') return null
  return (
    <Script
      src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
      onLoad={() => {
        new window.VConsole()
      }}
    />
  )
}

export default VConsole
