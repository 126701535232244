/* eslint-disable @next/next/no-img-element */
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/core-ui'
import Dialog from '@/components/core-ui/Dialog'
import { ManagedDialog } from '@/shared/constants'
import { useDialog } from '@/shared/hooks'
import { IDialogProps } from '@/shared/types'
import { selectCurrentUtilityValue } from '@/store/claim/claimSlice'
import { useAppSelector } from '@/store/hooks'

export const ConfirmUseBenefitsDialog = ({ isOpened, closeDialog }: IDialogProps) => {
  const currentUtilityValue = useAppSelector(selectCurrentUtilityValue)
  const { t } = useTranslation(['common', 'web-claim'])
  const { setDialog } = useDialog()
  const openUseBenefitsCommonBottomSheet = useCallback(() => {
    setDialog(ManagedDialog.claimUseCommonBenefits)
  }, [setDialog])

  const RenderDescriptionSection = useCallback(() => {
    if (currentUtilityValue?.isDive)
      return (
        <p className="whitespace-pre-line">{t('web-claim:web-claim.msg-confirm-staff-receive')}</p>
      )
    else {
      return (
        <p className="whitespace-pre-line">
          {t('web-claim:web-claim.msg-confirm-manager-receive')}
        </p>
      )
    }
  }, [currentUtilityValue?.isDive, t])

  return (
    <Dialog isOpen={isOpened} handleClose={closeDialog}>
      <div className="w-[295px] max-w-xs">
        <div className="w-full items-center justify-center flex flex-col">
          {currentUtilityValue?.isDive ? (
            <></>
          ) : (
            <img src="/img/img_manager.svg" alt="manager img" />
          )}
          <div className="text-xl font-semibold text-textPrimary">
            {t('web-claim:web-claim.label-notice_staff_01')}
          </div>
          <div className="text-sm text-textSecondary m-2 flex flex-col items-center justify-center w-full">
            <RenderDescriptionSection />
          </div>
        </div>
      </div>
      <Dialog.Footer>
        <Button
          text={t('common:common.cta-label-cancel')}
          theme="Outlined"
          size={'sm'}
          onClick={closeDialog}
        />
        <Button
          text={t('web-claim:web-claim.label-notice_staff_02')}
          theme="Accent"
          size={'sm'}
          onClick={openUseBenefitsCommonBottomSheet}
        />
      </Dialog.Footer>
    </Dialog>
  )
}
