import { useTranslation } from 'react-i18next'

function NotEnabled() {
  const { t } = useTranslation('app-modal')

  return (
    <div className="w-full mt-[60px] lg:mt-[132px]">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-5 items-center">
          <div className="flex flex-col gap-1 font-normal leading-normal text-sm lg:text-lg font-Pretendard text-center">
            <span>{t('app-modal:modal.error-unauthorized-access')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotEnabled
