import { ManagedDialog } from '@/shared/constants'

import {
  ClaimBenefitsDetailDialog,
  ClaimUseBenefitsCommonBottomSheet,
  ClaimUseBenefitsQRBottomSheet,
} from './managed-dialogs'
import { ClaimUseBenefitsVideoBottomSheet } from './managed-dialogs/ClaimUseBenefitsVideoBottomSheet'
import { ConfirmUseBenefitsDialog } from './managed-dialogs/ConfirmUseBenefitsDialog'

export interface IDialogManagerProps {
  dialog: ManagedDialog | null
  closeDialog: () => void
}

const DialogManager = ({ dialog, closeDialog }: IDialogManagerProps) => {
  return (
    <>
      <ClaimUseBenefitsQRBottomSheet
        isOpened={dialog === ManagedDialog.claimUseQRBenefits}
        closeDialog={closeDialog}
      />
      <ClaimUseBenefitsCommonBottomSheet
        isOpened={dialog === ManagedDialog.claimUseCommonBenefits}
        closeDialog={closeDialog}
      />
      <ClaimBenefitsDetailDialog
        isOpened={dialog === ManagedDialog.ClaimBenefitsDetail}
        closeDialog={closeDialog}
      />
      <ConfirmUseBenefitsDialog
        isOpened={dialog === ManagedDialog.confirmUseBenefits}
        closeDialog={closeDialog}
      />
      <ClaimUseBenefitsVideoBottomSheet
        isOpened={dialog === ManagedDialog.claimUseVideoBenefits}
        closeDialog={closeDialog}
      />
    </>
  )
}

export default DialogManager
