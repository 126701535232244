import {
  Action,
  AnyAction,
  CombinedState,
  combineReducers,
  configureStore,
  Reducer,
  ThunkAction,
} from '@reduxjs/toolkit'
import { createWrapper, HYDRATE } from 'next-redux-wrapper'
import logger from 'redux-logger'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import thunk from 'redux-thunk'

import authSlice, { IAuthState } from './auth/authSlice'
import claimSlice, { IClaimState } from './claim/claimSlice'
import { dialogSlice, IDialogState } from './dialog'
import { persistConfig } from './middleware'

export interface IReducerStates {
  [authSlice.name]: IAuthState
  [dialogSlice.name]: IDialogState
  [claimSlice.name]: IClaimState
}

const rootReducer = (state: IReducerStates, action: AnyAction): CombinedState<IReducerStates> => {
  switch (action.type) {
    case HYDRATE:
      return {
        state,
        ...action.payload,
      }

    default: {
      const combinedReducer = combineReducers({
        [authSlice.name]: authSlice.reducer,
        [dialogSlice.name]: dialogSlice.reducer,
        [claimSlice.name]: claimSlice.reducer,
      })
      return combinedReducer(state, action)
    }
  }
}

const makeConfiguredStore = (
  reducer: Reducer<IReducerStates | (IReducerStates & PersistPartial), AnyAction>,
) =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })

      // if (process.env.NODE_ENV === 'development') {
      middleware.concat(logger)
      // }
      return middleware.concat(thunk)
    },
    devTools: process.env.NODE_ENV === 'development',
  })

const makeStore = () => {
  const isServer = typeof window === 'undefined'

  if (isServer) {
    return makeConfiguredStore(rootReducer as Reducer<IReducerStates, AnyAction>)
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { persistStore, persistReducer } = require('redux-persist')

    const persistedReducer = persistReducer(
      persistConfig,
      rootReducer as Reducer<IReducerStates, AnyAction>,
    )
    const store = makeConfiguredStore(persistedReducer)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    store.__persistor = persistStore(store)

    return store
  }
}

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>

const wrapper = createWrapper<AppStore>(makeStore, {
  debug: process.env.NODE_ENV === 'development',
})

export default wrapper
