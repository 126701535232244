import { useTranslation } from 'react-i18next'

const ErrorFallback = ({ error, resetBoundary }: { error?: Error; resetBoundary?: () => void }) => {
  const { t } = useTranslation()

  return (
    <>
      <span data-testid="error-message">{error?.message}</span>
      <button data-testid="retry-button" onClick={resetBoundary}>
        {t('common:common.cta-label-try-again')}
      </button>
    </>
  )
}

export default ErrorFallback
