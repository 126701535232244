import { useLayoutEffect } from 'react'

export const useScrollLock = () => {
  useLayoutEffect(() => {
    const originalOverflow = window.getComputedStyle(document.body).overflow
    const originalPosition = window.getComputedStyle(document.body).position
    const originalScrollY = window.scrollY

    document.body.style.cssText = `
      width: 100%;
      position: fixed;
      overflow-y: scroll;
      top: -${originalScrollY}px;` // 현재의 위치로 고정시키기 위해 지정했습니다.

    // 컴포넌트가 언마운트될 때 스타일을 초기화하고 스크롤 위치를 복원합니다.
    return () => {
      document.body.style.cssText = `
        width: auto;
        position: ${originalPosition};
        overflow: ${originalOverflow};`

      window.scrollTo(0, originalScrollY)
    }
  }, [])
}
