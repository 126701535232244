import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

interface IRefreshContext {
  refresh: boolean
  refreshRefetch: () => void
  setRefetchFunc: (refreshFunc: () => void) => void
}
const RefreshContext = createContext<IRefreshContext>({
  refresh: false,
  refreshRefetch: () => undefined,
  setRefetchFunc: () => undefined,
})

export const RefreshProvider = ({ children }: { children: ReactNode }) => {
  const [refresh, setRefresh] = useState(false)
  const refetch = useRef<() => void | null>(null)

  const setRefetchFunc = useCallback((refetchFunc: () => void) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    refetch.current = refetchFunc
  }, [])

  useEffect(() => {
    if (refetch.current === null) return
    if (refresh) {
      refetch.current()
      setRefresh(false)
    }
  }, [refetch, refresh])

  return (
    <RefreshContext.Provider
      value={{ refresh, setRefetchFunc, refreshRefetch: () => setRefresh(true) }}>
      {children}
    </RefreshContext.Provider>
  )
}
export const useRefreshContext = () => useContext(RefreshContext)
