export const camelToUpperCase = (camelCaseString: string): string => {
  const result = camelCaseString.replace(/([a-z])([A-Z])/g, '$1_$2')

  return result.toUpperCase()
}

export const upperToCamelCase = (upperCaseString: string): string => {
  const result = upperCaseString.replace(/_([A-Z])/g, (_, char) => char.toLowerCase())

  return result.charAt(0).toLowerCase() + result.slice(1)
}
