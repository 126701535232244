import { useTranslation } from 'react-i18next'

import { Button } from '../core-ui'

const LoginTokenError = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('common')

  return (
    <div className="w-full mt-[60px] lg:mt-[132px]">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-5 items-center">
          <div className="flex flex-col gap-1 font-normal leading-normal text-sm lg:text-lg font-Pretendard text-center">
            <span>{t('common:common.error-auth-information')}</span>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Button
            theme="Filled"
            size="sm"
            text={t('common:common.cta-label-try-again')}
            onClick={onClick}
            className="max-w-[150px]"
          />
        </div>
      </div>
    </div>
  )
}

export default LoginTokenError
