import { PropsWithChildren, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import { useScrollLock } from '@/shared/hooks'
import { cx } from '@/shared/utils'

interface IBottomSheetProps extends PropsWithChildren {
  className?: string
  closeSheet: () => void
  center?: boolean
}

const BottomSheet = ({ children, closeSheet, className, center = true }: IBottomSheetProps) => {
  useScrollLock()

  const bottomSheetRootEl = document.querySelector('#bottom-sheet-root') as HTMLElement
  const el = useRef(document.createElement('div'))

  useEffect(() => {
    if (bottomSheetRootEl === null) return
    const current = el.current
    if (bottomSheetRootEl !== null) bottomSheetRootEl.appendChild(current)

    return () => {
      bottomSheetRootEl !== null && bottomSheetRootEl.removeChild(current)
    }
  }, [bottomSheetRootEl])

  return createPortal(
    <>
      <div
        className={cx(
          className,
          center ? 'left-[50%] translate-x-[-50%] sm:min-w-[500px] min-w-full' : 'left-0 w-[100vw]',
          'fixed bottom-0 h-[100vh] bg-appTextInversePrimary z-50',
        )}>
        {children}
      </div>
      <div
        className="z-40 top-0 left-0 fixed w-[100vw] h-[100vh] bg-[#000000CC]"
        onClick={closeSheet}
      />
    </>,
    el.current,
  )
}

export default BottomSheet
