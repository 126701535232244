import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/core-ui'
import Dialog from '@/components/core-ui/Dialog'
import { IDialogProps } from '@/shared/types'
import { selectCurrentUtilityValue } from '@/store/claim/claimSlice'
import { useAppSelector } from '@/store/hooks'

export const ClaimBenefitsDetailDialog = ({ isOpened, closeDialog }: IDialogProps) => {
  const { t } = useTranslation(['common', 'web-claim'])
  const currentUtilityValue = useAppSelector(selectCurrentUtilityValue)

  const benefitDetailDialogTitle = useMemo(() => {
    if (currentUtilityValue === null) return
    if (currentUtilityValue.isDive) return t('web-claim:web-claim.title-davinci-entry-information')
    if (currentUtilityValue.utilityType === 'TICKET') return t('web-claim:claim.guide-cta-label')
    if (currentUtilityValue.utilityType === 'UTILITY')
      return t('common:common.claim-learn-more-about-benefits')
  }, [currentUtilityValue, t])

  return (
    <Dialog isOpen={isOpened} handleClose={closeDialog}>
      <div className="w-[280px] max-w-xs">
        <Dialog.Header title={benefitDetailDialogTitle} />
        <div className="mt-6 flex flex-col gap-1 px-2 text-sm text-textSecondary">
          <div className="whitespace-pre-wrap">{currentUtilityValue?.utilityDescription}</div>
        </div>
        <Dialog.Footer>
          <Button
            text={t('common:common.cta-label-confirm')}
            theme="Outlined"
            size={'sm'}
            onClick={closeDialog}
            className={'mt-4'}
          />
        </Dialog.Footer>
      </div>
    </Dialog>
  )
}
