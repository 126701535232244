import { APP_PATH_TYPE } from '@/shared/constants'

export const goToAppUsingDeepLink = (pathType: keyof typeof APP_PATH_TYPE, isDev: boolean) => {
  const apn = 'com.kkrwallet'
  const ibi = `org.modernlion.kkrWallet${isDev ? 'Dev' : ''}`
  const urlMappings = {
    [APP_PATH_TYPE.HOME]: `https://konkrit.page.link/?link=https://konkrit.page.link?navigate%3Dhome&apn=${apn}&isi=123456789&ibi=${ibi}&efr=1`,
    [APP_PATH_TYPE.MYTEM]: `https://konkrit.page.link/?link=https://konkrit.page.link?navigate%3Dmytem&apn=${apn}&isi=123456789&ibi=${ibi}&efr=1`,
  }

  if (typeof window !== 'undefined') {
    window.location.href = urlMappings[pathType]
  }
}
