import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { useIsReactNativeWebView } from '@/shared/hooks'
import { useGetCodeForLogin } from '@/shared/hooks/useGetLoginCode'
import { useInitApp } from '@/shared/hooks/useInitApp'
import { sendMessageToApp, sendToSentryWithExtra } from '@/shared/utils'
import {
  resetAuthState,
  selectAccessToken,
  selectCanUsePrivateAPI,
  selectIsGetTokenError,
  setLogin,
} from '@/store/auth/authSlice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'

import { Spinner } from '../core-ui'
import { LoginError, LoginTokenError, NotEnabled } from '../error'

const AuthGuard = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch()
  const canUsePrivateAPI = useAppSelector(selectCanUsePrivateAPI)
  const accessToken = useAppSelector(selectAccessToken)
  const isGetTokenError = useAppSelector(selectIsGetTokenError)

  const isWebView = useIsReactNativeWebView()
  const [requestedCodeForLogin, setRequestedCodeForLogin] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthError, setIsAuthError] = useState(false)
  const {
    data,
    isError: isLoginCodeError,
    setIsError: setIsLoginCodeError,
    requestCodeForLogin,
    setData,
  } = useGetCodeForLogin()

  useInitApp()

  const requestAccessToken = useCallback(async () => {
    if (typeof window === undefined || !isWebView) return

    dispatch(resetAuthState())
    dispatch(setLogin({ loginCode: data }))
    setData('')
  }, [data, dispatch, isWebView, setData])

  useEffect(() => {
    if (isLoginCodeError) {
      setIsLoading(false)
      setIsAuthError(true)
      sendMessageToApp<string>('Message', 'FailedLogin')
      sendToSentryWithExtra(new Error('Failed to get code for login'))
    }
  }, [isLoginCodeError])

  useEffect(() => {
    if (isGetTokenError !== null) {
      setIsLoading(false)
      setIsAuthError(true)
      sendMessageToApp<string>('Message', 'FailedLogin')
    }
  }, [isGetTokenError])

  const isCanRequestCodeForLogin = useMemo(() => {
    return typeof requestedCodeForLogin === 'number' && requestedCodeForLogin === 0 && data === ''
  }, [data, requestedCodeForLogin])

  useEffect(() => {
    if (!canUsePrivateAPI && data !== '' && accessToken === null && requestedCodeForLogin === 1) {
      requestAccessToken()
    }
  }, [accessToken, canUsePrivateAPI, data, requestAccessToken, requestedCodeForLogin])

  useEffect(() => {
    if (isCanRequestCodeForLogin) {
      const timerId = setTimeout(() => {
        requestCodeForLogin()
        setRequestedCodeForLogin(retry => retry + 1)
      }, 200)
      return () => clearTimeout(timerId)
    }
  }, [data, isCanRequestCodeForLogin, requestCodeForLogin, requestedCodeForLogin])

  useEffect(() => {
    if (accessToken !== null && requestedCodeForLogin > 0) {
      setIsLoginCodeError(false)
      setIsAuthError(false)
      setIsLoading(false)
      sendMessageToApp<string>('Message', 'SuccessfulLogin')
    }
  }, [accessToken, requestedCodeForLogin, setIsLoginCodeError])

  const initAuthGuardState = useCallback(() => {
    setIsLoading(true)
    setIsLoginCodeError(false)
    setRequestedCodeForLogin(1)
    dispatch(resetAuthState())
    requestCodeForLogin()
  }, [dispatch, requestCodeForLogin, setIsLoginCodeError])

  useEffect(() => {
    return () => {
      setIsLoading(true)
      setIsLoginCodeError(false)
      setRequestedCodeForLogin(0)
      dispatch(resetAuthState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <div className="min-h-[100vh] flex align-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (isWebView === false) {
    return <NotEnabled />
  }

  if (isLoginCodeError) return <LoginTokenError onClick={initAuthGuardState} />

  if (isAuthError) {
    return <LoginError />
  }

  if (accessToken !== null) return <>{children}</>
  return <></>
}

export default AuthGuard
