/* eslint-disable @typescript-eslint/ban-ts-comment */
import '@/styles/globals.css'
import 'react-toastify/dist/ReactToastify.css'

import { MantineProvider } from '@mantine/core'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'

import { VConsole } from '@/components/dev'
import { DialogProvider } from '@/components/dialogs'
import AuthGuard from '@/components/hoc/AuthGuard'
import { RefreshProvider } from '@/shared/contexts/RefreshContext'
import { getConfig } from '@/shared/services/config'
import { LocalStorage } from '@/shared/utils'
import wrapper from '@/store'

import i18nextConfig from '../next-i18next.config'

export type NextPageWithLayout<P = Record<any, any>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { store } = wrapper.useWrappedStore(pageProps)

  const [queryClient] = useState(() => new QueryClient())

  const fetchConfig = useCallback(async () => {
    try {
      const config = await getConfig()
      LocalStorage.setItem('config', JSON.stringify(config))
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    fetchConfig()
  }, [fetchConfig])

  const getLayout = Component.getLayout ?? (page => page)

  return (
    <>
      <Head>
        <title>대체 불가능한 NFT 거래 플랫폼 콘크릿 KONKRIT</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.png" />
        <meta name="theme-color" content="#18191a" />
        <meta
          name="description"
          content="NFT 거래, 더 KONKRIT(콘크릿)하게. 실생활 밀착형 유틸리티 기반의 NFT부터 PFP, Art, 연예인 NFT까지. 모두 NFT 마켓 플레이스 콘크릿에서 만나보세요."
        />
        <meta property="og:title" content="대체 불가능한 NFT 거래 플랫폼 KONKRIT" />
        <meta name="keywords" content="콘크릿, 컨크릿, 콘크릿 베타, 컨크릿 베타" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="NFT 거래, 더 KONKRIT(콘크릿)하게. 실생활 밀착형 유틸리티 기반의 NFT부터 PFP, Art, 연예인 NFT까지. 모두 NFT 마켓 플레이스 콘크릿에서 만나보세요."
        />
        <meta property="og:image" content="/og_image.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link rel="apple-touch-icon" href="/favicon_192.png" />

        <link rel="manifest" href="/manifest.json" />
      </Head>
      <>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            {/* @ts-ignore */}
            <PersistGate
              loading={<></>}
              // @ts-ignore
              persistor={store.__persistor}>
              <MantineProvider>
                <RefreshProvider>
                  <DialogProvider>
                    <AuthGuard>{getLayout(<Component {...pageProps} />)}</AuthGuard>
                  </DialogProvider>
                </RefreshProvider>
              </MantineProvider>
            </PersistGate>
            <ToastContainer
              toastClassName={() =>
                'px-[14.5px] py-2 bg-[#FAFAFA] shadow-[0px 4px 8px]/[0.16] text-black rounded-lg items-center max-w-max mx-auto my-[2px]'
              }
              autoClose={4000}
              position="bottom-center"
              hideProgressBar={true}
              pauseOnHover={true}
              closeButton={false}
            />
          </Hydrate>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>

        <VConsole />
      </>
    </>
  )
}

export default wrapper.withRedux(appWithTranslation(App, i18nextConfig))
