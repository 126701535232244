import { IConfig } from '../types/config'
import { camelToUpperCase } from './caseConversion'
import LocalStorage from './localstorage'

const isClientSide = typeof window !== 'undefined'

const getStoredInLocalStorage = () => {
  if (!isClientSide) return null
  const storedConfig = LocalStorage.getItem('config')
  return storedConfig !== null ? JSON.parse(storedConfig) : null
}

export const getEnvValue = (key: keyof IConfig): string | null => {
  if (isClientSide) {
    const storedConfig = getStoredInLocalStorage()
    return storedConfig !== null ? storedConfig[key] : null
  } else {
    return process.env[camelToUpperCase(key)] ?? null
  }
}
