import { cx } from '@/shared/utils'

export default function Spinner({ size = 'base' }: { size?: 'sm' | 'base' }) {
  return (
    <div className="flex items-center justify-center">
      <div className={cx(size === 'base' ? 'w-14 h-14' : 'w-10 h-10', 'animate-spin')}>
        <img src="/img/loading.svg" alt="loading" className="w-full h-full" />
      </div>
    </div>
  )
}
