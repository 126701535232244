import axios from 'axios'

export const getAccessToken = async ({ code }: { code: string }) => {
  const {
    data: { access_token, refresh_token },
  } = await axios({
    url: '/api/oauth/token',
    method: 'POST',
    timeout: 30000,
    data: {
      code: code,
    },
  })

  return { access_token, refresh_token }
}
