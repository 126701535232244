import { useEffect, useState } from 'react'

import { resetAuthState } from '@/store/auth/authSlice'
import { useAppDispatch } from '@/store/hooks'

import { sendMessageToApp } from '../utils'

export const useGetCodeForLogin: () => {
  data: string
  isError: boolean
  setIsError: (isError: boolean) => void
  requestCodeForLogin: () => void
  setData: (data: string) => void
} = () => {
  const dispatch = useAppDispatch()
  const [data, setData] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)

  const requestCodeForLogin = () => sendMessageToApp<string>('Message', 'RequestCodeForLogin')

  const listener = (event: any) => {
    if (!window.ReactNativeWebView || event.data === 'undefined') return

    const { type, data } = JSON.parse(event.data)

    if (type === 'CodeForLogin') {
      dispatch(resetAuthState())
      setData(data)
    }
    if (type === 'ErrorCodeForLogin') {
      setIsError(true)
    }
  }

  useEffect(() => {
    ;(() => {
      document.addEventListener('message', listener)
      window.addEventListener('message', listener)
    })()
    return () => {
      document.removeEventListener('message', listener)
      window.removeEventListener('message', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { data, isError, setIsError, requestCodeForLogin, setData }
}
