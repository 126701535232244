import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getAccessToken } from '@/shared/services/auth'
import { sendToSentryWithExtra } from '@/shared/utils'
import { getEnvValue } from '@/shared/utils/getEnvValue'

import { AppState, AppThunk } from '..'

export interface IAuthState {
  accessToken: string | null
  isGetTokenError: string | null
  canUsePrivateAPI: boolean
  isLoginProcessing: boolean
}

export const initialState: IAuthState = {
  accessToken: null,
  canUsePrivateAPI: false,
  isGetTokenError: null,
  isLoginProcessing: false,
}

export const getToken = createAsyncThunk(
  'wallet/getToken',
  async ({ loginCode }: { loginCode: string }) => {
    try {
      const { access_token: accessToken, refresh_token: refreshToken } = await getAccessToken({
        code: loginCode,
      })

      return { accessToken, refreshToken }
    } catch (e: any) {
      sendToSentryWithExtra(e)
      return Promise.reject(e)
    }
  },
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: state => {
      state.accessToken = null
      state.canUsePrivateAPI = false
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setCanUsePrivateAPI: (state, action) => {
      state.canUsePrivateAPI = action.payload
    },
    setIsLoginProcessing: (state, action) => {
      state.isLoginProcessing = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getToken.pending, state => {
      state.isGetTokenError = null
      state.isLoginProcessing = true
    })
    builder.addCase(getToken.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken
      const oauthRefreshTokenExpiresIn = getEnvValue('oauthRefreshTokenExpiresIn') ?? '3600000'
      const currentTimestamp = new Date().getTime()
      const refreshTokenExpiresAt = currentTimestamp + Number(oauthRefreshTokenExpiresIn)
      localStorage.setItem('accessToken', action.payload.accessToken)
      localStorage.setItem('refreshToken', action.payload.refreshToken)
      localStorage.setItem('refreshTokenExpiresAt', `${refreshTokenExpiresAt}`)
      state.canUsePrivateAPI = true
      state.isLoginProcessing = false
    })
    builder.addCase(getToken.rejected, (state, action) => {
      state.isLoginProcessing = false
      state.canUsePrivateAPI = false
      if (action.error.message) state.isGetTokenError = action.error.message
    })
  },
})

export const selectAccessToken = (state: AppState) => state.auth.accessToken
export const selectCanUsePrivateAPI = (state: AppState) => state.auth.canUsePrivateAPI
export const selectIsGetTokenError = (state: AppState) => state.auth.isGetTokenError
export const selectIsLoginProcessing = (state: AppState) => state.auth.isLoginProcessing

export const { resetAuthState, setAccessToken, setCanUsePrivateAPI, setIsLoginProcessing } =
  authSlice.actions

export const setLogin =
  ({ loginCode }: { loginCode?: string }): AppThunk =>
  async (dispatch, getState) => {
    if (loginCode === undefined) return
    dispatch(resetAuthState())

    await dispatch(getToken({ loginCode }))

    const isGetTokenError = selectIsGetTokenError(getState())
    if (isGetTokenError !== null) {
      return dispatch(resetAuthState())
    }
  }

export default authSlice
