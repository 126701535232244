import { useEffect } from 'react'

import { resetAuthState } from '@/store/auth/authSlice'
import { useAppDispatch } from '@/store/hooks'

export const useInitApp = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTimestamp = new Date().getTime()
      const refreshTokenExpiresAt = localStorage.getItem('refreshTokenExpiresAt')

      if (refreshTokenExpiresAt !== null && currentTimestamp < Number(refreshTokenExpiresAt)) {
        return
      } else {
        dispatch(resetAuthState())
        clearInterval(interval)
      }
    }, 30000)
    return () => clearInterval(interval)
  }, [dispatch])
}
