import { ManagedDialog } from '@/shared/constants'
import {
  selectIsPreparingToOpenDialog,
  selectSelectedDialog,
  setDialog,
  setIsPreparingToOpenDialog,
} from '@/store/dialog'
import { useAppDispatch, useAppSelector } from '@/store/hooks'

export const useDialog = () => {
  const dispatch = useAppDispatch()
  const selectedDialog = useAppSelector(selectSelectedDialog)
  const isPreparingToOpenDialog = useAppSelector(selectIsPreparingToOpenDialog)

  const handleSpecificDialog = (dialog: ManagedDialog) => (isOpened: boolean) => {
    dispatch(setDialog(isOpened ? dialog : null))
  }

  return {
    setDialog: (dialog: ManagedDialog | null) => dispatch(setDialog(dialog)),
    selectedDialog,
    handleSpecificDialog,
    isPreparingToOpenDialog,
    setIsPreparingToOpenDialog: (isOpened: boolean) =>
      dispatch(setIsPreparingToOpenDialog(isOpened)),
  }
}
