import { useQuery } from '@tanstack/react-query'

import { IPublicUtilities, showType } from '@/shared/types'
import withAxios from '@/shared/utils/api'

interface IGetPublicUtilitiesParamsType {
  showType: keyof typeof showType
}

export const getPublicUtilities = async (
  params?: IGetPublicUtilitiesParamsType,
  itemId?: string,
) => {
  const url = `/api/v2/public/utilities/${itemId}`

  const { data } = await withAxios<IPublicUtilities>({
    url,
    method: 'GET',
    params,
  })

  return data
}

export const useGetPublicUtilities = (
  pathname: string,
  params: IGetPublicUtilitiesParamsType,
  itemId?: string,
) => {
  return useQuery(
    [pathname, 'getPublicUtilities', { params }],
    () => getPublicUtilities(params, itemId),
    {
      enabled: itemId !== undefined,
      retry: 2,
      retryDelay: 1000,
    },
  )
}
